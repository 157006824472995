import React from 'react';
import Launch from '../images/launch.svg';
import Dream3 from '../images/dream3.png';
import Zaptheory from '../images/zaptheory.png';
import Realnifty from '../images/realnifty.png';
import DigitClub from '../images/digitclub.png';
import QuickSwap from '../images/quickswap.png';
import Rareshot from '../images/rareshot.png';
// import Discodogs from '../images/discodogs.png';
// import Kandikids from '../images/Kandikids.png';
// import Mosaic from '../images/mosaic.png';

function Ourwork() {
  const items = [
    {
      tag: 'NFT Marketplace',
      name: 'RealNifty',
      intro:
        'A unique NFT marketplace bridging the physical and digital worlds for art, collectibles, fashion and more.',
      image: Realnifty,
      link: 'https://realnifty.xyz',
      isNew: true,
    },
    {
      tag: 'Defi',
      name: 'QuickSwap',
      intro:
        'QuickSwap is a next-generation layer-2 decentralized exchange and Automated Market Maker.',
      image: QuickSwap,
      link: 'https://quickswap.exchange/',
    },
    // {
    //   tag: 'Social Tokens',
    //   name: 'Zaptheory',
    //   intro:
    //     'An open network that enables creators to launch independent token economies with their communities',
    //   image: Zaptheory,
    //   link: 'https://zaptheory.com',
    // },
    {
      tag: 'Hollywood + Generative NFT',
      name: 'Dream3',
      intro: 'The first transparent movie studio on the blockchain',
      image: Dream3,
      link: 'https://dream3.io/',
    },
    {
      tag: 'Photography',
      name: 'Rareshot',
      intro: 'A photography social media platform and web3 NFT platform that connects photographers worldwide.',
      image: Rareshot,
      link: 'https://beta.rareshots.xyz/',
      isNew: true,
    },
    // {
    //   tag: 'ENS Price Tracker',
    //   name: 'DigitClub',
    //   intro: 'A price tracker for special ENS domains',
    //   image: DigitClub,
    //   link: 'https://www.digitclub.xyz/',
    // },
    // {
    //     tag: 'Generative NFT',
    //     name: 'KandiKids',
    //     intro: 'An online marketplace for Artist, Collectors and Curators',
    //     image: Kandikids,
    //     link: 'https://www.kandikids.io/'
    // },
    // {
    //   tag: 'Physical + Digital NFT',
    //   name: 'Mosaic',
    //   intro:
    //     'A unique Physical & digital NFT collection drop by the renowned artist Matt Vegh',
    //   image: Mosaic,
    //   link: 'https://mosaic.realnifty.io/',
    // },
    // {
    //   tag: 'Generative NFT',
    //   name: 'Discodogs',
    //   intro: 'Exclusive membership to music events in the Metaverse',
    //   image: Discodogs,
    //   link: 'https://www.discodogsnft.com/',
    // },
  ];
  const handleClick = (item)=>{
    window.open(item.link, '_blank')
  }

  return (
    <section className='our-work-container' id='our_work'>
      <h4>{'<OUR WORK/>'}</h4>

      <div className='tw-flex lg:tw-flex-row tw-flex-col tw-flex-wrap tw-items-center tw-place-content-center'>
        {items.map((item, i) => {
          return (
            <div
              className='card img-card tw-relative tw-cursor-pointer'
              style={{ backgroundImage: 'url(' + item.image + ')' }}
              key={i}
              onClick={()=>handleClick(item)}
              // href={item.link}
              // target='_blank'
              // without
              // rel='noreferrer noopener'
            >
              {item.isNew === true && (
                <div class='ribbon ribbon-top-right'>
                  <span>New!</span>
                </div>
              )}
              <span className='tag'>{item.tag}</span>

              <div className='name'>{item.name}</div>
              <div className='intro'>{item.intro}</div>

              <a
                className='link tw-flex tw-flex-row tw-items-center tw-place-content-center'
                //  (click)="visit(item)"
                href={item.link}
                target='_blank'
                without
                rel='noreferrer noopener'
              >
                <img src={Launch} alt={item.name} />
              </a>
            </div>
          );
        })}
      </div>
    </section>
  );
}

export default Ourwork;
